export function useBodyOverflowControl () {
  const preventScroll = (event: TouchEvent): void => {
    event.preventDefault()
  }

  const disable = (): void => {
    document.body.classList.add('overflow-hidden')

    // document.body.addEventListener('touchstart', preventScroll, { passive: false })
    // document.body.addEventListener('touchmove', preventScroll, { passive: false })
  }

  const enable = (): void => {
    document.body.classList.remove('overflow-hidden')

    // document.body.removeEventListener('touchstart', preventScroll)
    // document.body.removeEventListener('touchmove', preventScroll)
  }

  onUnmounted(() => {
    enable()
  })

  return {
    enable,
    disable
  }
}
