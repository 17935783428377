<template>
  <div class="
    skeleton-card box-border flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-main-very-light-gray animate-pulse
    sm:rounded-3xl
  ">
    <div class="relative">
      <div class="skeleton-image bg-[#f2f2f2] mb-4"></div>

      <div class="skeleton-text bg-main-gray-low h-4 mb-2 w-3/4"></div>
      <div class="skeleton-text bg-main-gray-low h-4 w-1/2"></div>

      <div class="absolute right-2 top-2 z-[2] flex flex-col items-end">
        <div class="skeleton-button bg-main-gray h-6 w-6 mb-2 rounded-full"></div>
        <div class="skeleton-button bg-main-gray h-6 w-6 rounded-full"></div>
      </div>
    </div>

    <div class="p-2 sm:p-3">
      <div class="skeleton-price bg-main-light-gray h-4 mb-3 w-1/2"></div>
      <div class="skeleton-button bg-main-gray h-10 w-full rounded-md"></div>
    </div>
  </div>
</template>

<style scoped>
.skeleton-image {
  height: 252px;
  width: 100%;
  border-radius: 0.5rem;
}

.skeleton-text,
.skeleton-price,
.skeleton-button {
  border-radius: 0.25rem;
}
</style>
