import MindboxApiService from '~/services/mindbox-api-service'
import { useSearchboosterStore } from '~/store/searchbooster'
import type { ProductData } from '~/types/products'

export function useSearchProductsObserver () {
  const previousProducts = ref<ProductData[]>([])

  const searchboosterStore = useSearchboosterStore()
  const { getSearchProducts } = storeToRefs(searchboosterStore)

  watchEffect(() => {
    if (
      getSearchProducts.value.length > 0
      && searchboosterStore.filter.query
      && searchboosterStore.filter.query.length > 2
    ) {
      const firstThreeProducts = getSearchProducts.value.slice(0, 3)

      const hasChanged = firstThreeProducts.some((product: ProductData, index: number): boolean => {
        const prevProduct = previousProducts.value[index]

        return !prevProduct || JSON.stringify(toRaw(product)) !== JSON.stringify(toRaw(prevProduct))
      })

      if (hasChanged) {
        previousProducts.value = [...firstThreeProducts]

        MindboxApiService.setSearch(firstThreeProducts)
      }
    }
  })
}
