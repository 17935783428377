export function debounce (fn: Function, delay: number) {
  let timeoutId: number | undefined
  let abortController: AbortController | undefined

  return function (...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    if (abortController) {
      abortController.abort()
    }

    abortController = new AbortController()
    const signal = abortController.signal

    timeoutId = window.setTimeout(() => {
      fn(signal, ...args)
    }, delay)
  }
}
